<h1 mat-dialog-title>{{ 'customer_file' | translate }}</h1>
<div class="mat-typography" mat-dialog-content>
    <form [formGroup]="form" class="flex flex-col gap-2">
        <div>
            <div class="mat-subtitle-2">{{ 'name' | translate }}</div>
            <sz-input
                formControlName="name"
                [showError]="form.controls.name.invalid"
                errorMessage="form.field_required"
            />
        </div>
        <div>
            <div class="mat-subtitle-2">{{ 'type' | translate }}</div>
            <sz-input
                formControlName="type"
                [showError]="form.controls.type.invalid"
                errorMessage="form.field_required"
            />
        </div>
        <div>
            <div class="mat-subtitle-2">{{ 'version' | translate }}</div>
            <sz-input
                formControlName="versionNumber"
                [showError]="form.controls.versionNumber.invalid"
                errorMessage="form.field_required"
            />
        </div>
        <mat-checkbox formControlName="isActive" class="active-checkbox">
            {{ 'dialog.active' | translate }}
        </mat-checkbox>
    </form>
    <sz-dialog-button-bar
        [primaryDisabled]="!form.valid"
        (primaryClick)="dialogRef.close(form.value)"
        (secondaryClick)="dialogRef.close()"
    />
</div>
