import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

interface EditMqttCommandDialogData {
    title: string;
    currentName: string;
    currentTopic: string;
}

@Component({
    templateUrl: './edit-mqtt-command-dialog.component.html',
})
export class EditMqttCommandDialog {
    form = new FormGroup({
        name: new FormControl(this.data.currentName ?? '', [
            Validators.required,
        ]),
        topic: new FormControl(this.data.currentTopic ?? '', [
            Validators.required,
        ]),
    });

    constructor(
        public dialogRef: MatDialogRef<EditMqttCommandDialog>,
        @Inject(MAT_DIALOG_DATA) public data: EditMqttCommandDialogData,
        public translate: TranslateService
    ) {}

    handleSave() {
        this.dialogRef.close(this.form.value);
    }
}
