<h1 mat-dialog-title>{{ 'title.new_model' | translate }}</h1>
<div mat-dialog-content class="mat-typography">
    <div [formGroup]="form" class="flex flex-col gap-2">
        <div>
            <div class="mat-subtitle-2">
                {{ 'name' | translate }}
            </div>
            <sz-input formControlName="name" />
        </div>
        <div>
            <div class="mat-subtitle-2">
                {{ 'type' | translate }}
            </div>
            <sz-select formControlName="deviceType" [options]="typeOptions" />
        </div>
        <div *ngIf="oemOptions.length > 0">
            <div class="mat-subtitle-2">
                {{ 'customer.role.oem' | translate }}
            </div>
            <sz-select formControlName="customerId" [options]="oemOptions" />
        </div>
        <div>
            <div class="mat-subtitle-2">
                {{ 'model.description' | translate }}
            </div>
            <sz-input formControlName="description" />
        </div>
    </div>
    <sz-dialog-button-bar
        [primaryDisabled]="!form.valid"
        (primaryClick)="dialogRef.close(form.value)"
        (secondaryClick)="dialogRef.close()"
    />
</div>
