import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomerFile } from 'src/app/shared/models/customer-file';

@Component({ templateUrl: './upload-customer-file-dialog.component.html' })
export class UploadCustomerFileDialog implements OnInit {
    item;
    file: CustomerFile;
    form = new FormGroup({
        name: new FormControl('', [Validators.required]),
        type: new FormControl('', [Validators.required]),
        isActive: new FormControl(true),
        versionNumber: new FormControl('', [Validators.required]),
    });

    constructor(
        public dialogRef: MatDialogRef<UploadCustomerFileDialog>,
        @Inject(MAT_DIALOG_DATA) public data,
    ) {
        this.file = data.file;
        this.file.name =
            this.file.name.lastIndexOf('.') >= 0
                ? this.file.name.split('.')[0]
                : this.file.name;
    }

    ngOnInit() {
        this.form.patchValue(this.file, { emitEvent: false });
    }
}
