<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<div mat-dialog-content class="mat-typography">
    <form [formGroup]="form" class="flex flex-col flex-wrap gap-3">
        <div>
            <div class="mat-subtitle-2">
                {{ 'name' | translate }}
            </div>
            <sz-input formControlName="name" />
        </div>
        <div>
            <div class="mat-subtitle-2">
                {{ 'common.topic' | translate }}
            </div>
            <sz-input formControlName="topic" />
        </div>
    </form>
    <sz-dialog-button-bar
        [primaryDisabled]="!form.valid"
        (primaryClick)="handleSave()"
        (secondaryClick)="dialogRef.close()"
    />
</div>
