import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SelectOption } from 'src/app/settings/settings.model';
import { ApiService } from 'src/app/services/api/api.service';
import {
    CanDatabase,
    DataCanDatabase,
} from 'src/app/shared/models/can-database';

@Component({ templateUrl: './update-can-database-dialog.component.html' })
export class UpdateCanDatabaseDialog implements OnInit {
    item: Partial<CanDatabase>;
    restrictions;
    baudRateOptions: SelectOption[] = [];
    busOptions: SelectOption[] = [];
    protocolOptions: SelectOption[] = [];

    constructor(
        public dialogRef: MatDialogRef<UpdateCanDatabaseDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DataCanDatabase,
        private api: ApiService
    ) {}

    async ngOnInit() {
        this.item = {};
        if (this.data?.item) {
            this.item = { ...this.data.item };
        }
        this.restrictions = {};
        if (this.data?.restrictions) {
            if (this.data.restrictions.has('can-databases')) {
                this.restrictions = this.data.restrictions.get('can-databases');
            }
        }
        let baudRates = await this.api.canDatabases.getBaudRates();
        if (baudRates) {
            if (this.restrictions['baud_rate_ids']) {
                baudRates = baudRates.filter((item) =>
                    this.restrictions['baud_rate_ids'].includes(item.id)
                );
            }
            this.baudRateOptions = baudRates.map(({ id, baudRate }) => ({
                value: id,
                name: baudRate,
            }));
        }
        let busses = await this.api.canDatabases.getBusses();
        if (busses) {
            if (this.restrictions['bus_number_ids']) {
                busses = busses.filter((item) =>
                    this.restrictions['bus_number_ids'].includes(item.id)
                );
            }
            this.busOptions = busses.map(({ id, bus }) => ({
                value: id,
                name: bus,
            }));
        }
        let protocols = await this.api.canDatabases.getProtocols();
        if (protocols) {
            if (this.restrictions['protocol_ids']) {
                protocols = protocols.filter((item) =>
                    this.restrictions['protocol_ids'].includes(item.id)
                );
            }
            this.protocolOptions = protocols.map(({ id, name }) => ({
                value: id,
                name,
            }));
        }
    }

    confirm() {
        const baud = this.baudRateOptions.find(
            ({ value }) => value == this.item.id
        );
        this.item.baudRateValue = baud?.value as string;
        this.item.isActive = this.item.isActive ?? false;
        this.dialogRef.close(this.item);
    }
}
