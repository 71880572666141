import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api/api.service';
import { ensureLoaded } from 'src/app/services/api/cached-api';
import { CustomerRole } from 'src/app/services/constants';
import { SelectOption } from 'src/app/settings/settings.model';
import { getCustomerId, hasRole, isRole } from 'src/app/shared/common';

@Component({
    selector: 'app-create-model-dialog',
    templateUrl: './create-model-dialog.component.html',
})
export class CreateModelDialog implements OnInit {
    form: FormGroup;
    typeOptions: SelectOption[] = [];
    oemOptions: SelectOption[] = [];

    constructor(
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<CreateModelDialog>,
        private api: ApiService
    ) {
        this.form = this.formBuilder.group({
            name: ['', Validators.required],
            customerId: ['', Validators.required],
            deviceType: ['', Validators.required],
            description: ['', Validators.required],
        });
    }

    async ngOnInit() {
        await ensureLoaded([this.api.organizations]);
        if (hasRole(CustomerRole.Administrator)) {
            const oems = this.api.organizations.current({
                role: CustomerRole.OEM,
            });
            this.oemOptions = oems.map((o) => ({ name: o.name, value: o.id }));
        } else if (isRole(CustomerRole.OEM)) {
            const oemId = getCustomerId();
            this.form.get('customerId').setValue(oemId);
        }

        const types = await this.api.models.getTypes();
        this.typeOptions = types.map(({ id, name }) => ({ name, value: id }));
    }
}
