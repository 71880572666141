<h1 mat-dialog-title>
    {{ 'can_database' | translate }}
</h1>
<div mat-dialog-content class="!flex flex-col gap-2">
    <h4>{{ item.fileLocation | fileName }}</h4>
    <div>
        <div class="mat-subtitle-2">{{ 'title' | translate }}</div>
        <sz-input [(ngModel)]="item.title" />
    </div>
    <div>
        <div class="mat-subtitle-2">{{ 'version' | translate }}</div>
        <sz-input [(ngModel)]="item.versionNumber" />
    </div>
    <div>
        <div class="mat-subtitle-2">
            {{ 'can_database.bus_number' | translate }}
        </div>
        <sz-select [options]="busOptions" [(ngModel)]="item.busNumberId" />
    </div>
    <div *ngIf="item.busNumberId !== null">
        <div class="mat-subtitle-2">
            {{ 'can_database.baud_rate' | translate }}
        </div>
        <sz-select [options]="baudRateOptions" [(ngModel)]="item.baudRateId" />
    </div>
    <div>
        <div class="mat-subtitle-2">
            {{ 'can_database.protocol' | translate }}
        </div>
        <sz-select [options]="protocolOptions" [(ngModel)]="item.protocolId" />
    </div>
    <mat-checkbox [(ngModel)]="item.isActive">
        {{ 'dialog.active' | translate }}
    </mat-checkbox>
    <sz-dialog-button-bar
        (primaryClick)="confirm()"
        (secondaryClick)="dialogRef.close()"
        [primaryDisabled]="
            !item.title ||
            !item.versionNumber ||
            item.busNumberId === null ||
            !item.protocolId ||
            !item.baudRateId
        "
    />
</div>
